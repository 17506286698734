import Loading from "@components/Loading/Loading";
import { classNames } from "@lib/helpers";
import Link from "next/link";
import React, { MouseEventHandler, ReactNode } from "react";

export interface AppButtonProps {
  style?: React.CSSProperties;
  type?: "button" | "submit" | "reset";
  children?: ReactNode;
  href?: string | null;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
  variant?: "contained" | "outlined" | "text";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  justify?: "center" | "start" | "end";
  color?: "secondary" | "sky-blue" | "danger" | "primary" | "blue";
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const AppButton = ({
  type,
  children,
  href,
  startIcon,
  endIcon,
  className,
  variant = "contained",
  size = "medium",
  disabled,
  justify = "center",
  color = "secondary",
  loading,
  onClick,
}: AppButtonProps) => {
  return href ? (
    <Link
      href={href}
      className={classNames(
        `inline-flex items-center border rounded-xl transition disabled:opacity-60`,
        `btn-${variant}-${color}`,
        className,
        justify === "center" ? "justify-center" : "",
        justify === "start" ? "justify-start" : "",
        justify === "end" ? "justify-end" : "",
        variant === "text" ? "text-dark-1 border-transparent" : "",
        // variant === "contained"
        //   ? "bg-secondary hover:bg-secondary-dark disabled:bg-secondary text-white border-transparent"
        //   : "",
        // variant === "outlined"
        //   ? "border-secondary text-secondary hover:bg-secondary disabled:bg-white hover:text-white disabled:text-secondary"
        //   : "",
        size === "small" ? "py-2 sm:py-2 px-3 sm:px-3.5 gap-x-0.5" : "",
        size === "medium" ? "sm:px-7 px-5 py-3 sm:py-3.5 gap-x-1.5" : "",
        size === "large" ? "sm:py-4 sm:px-6 px-3 py-2 gap-x-4" : ""
      )}
    >
      {startIcon}
      {children ? <span>{children}</span> : null}
      {endIcon}
    </Link>
  ) : (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={classNames(
        `relative inline-flex items-center border rounded-md transition disabled:opacity-60`,
        `btn-${variant}-${color}`,
        className,
        justify === "center" ? "justify-center" : "",
        justify === "start" ? "justify-start" : "",
        justify === "end" ? "justify-end" : "",
        variant === "text" ? "text-dark-1 border-transparent" : "",
        // variant === "contained"
        //   ? "bg-secondary hover:bg-secondary-dark disabled:bg-secondary text-white border-transparent"
        //   : "",
        // variant === "outlined"
        //   ? "border-secondary text-secondary hover:bg-secondary disabled:bg-white hover:text-white disabled:text-secondary"
        //   : "",
        size === "small" ? "py-2 sm:py-2 px-3 sm:px-3.5 gap-x-0.5" : "",
        size === "medium" ? "sm:px-7 px-5 py-3 sm:py-3.5 gap-x-1.5" : "",
        size === "large" ? "py-4 px-6 gap-x-4" : ""
      )}
    >
      {startIcon}

      {children ? (
        <span className={classNames(loading ? "opacity-0" : "")}>
          {children}
        </span>
      ) : null}
      {endIcon}
      {loading ? (
        <span
          className="w-10 absolute inline-block left-1/2 transform -translate-x-1/2"
          style={{ margin: 0 }}
        >
          <Loading />
        </span>
      ) : null}
    </button>
  );
};

export default AppButton;
