"use client"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade } from 'swiper/modules';
import Image from 'next/image';

type Props = {
    images: string[]
    spaceBetween: number,
    effect: string,
    navigation: boolean,
    paginationClickable: boolean,
    autoplayDelay: number,
    className: string, 
}

const RegisterImagesSlider = ({
    images = [],
    spaceBetween = 30,
    effect = 'fade',
    navigation = true,
    paginationClickable = true,
    autoplayDelay = 4500,
    className = 'mySwiper',
}: Props) => {
    return (
        <Swiper
            spaceBetween={spaceBetween}
            effect={effect}
            navigation={navigation}
            pagination={{
                clickable: paginationClickable,
            }}
            autoplay={{
                delay: autoplayDelay,
                disableOnInteraction: false,
            }}
            modules={[Autoplay, EffectFade]}
            className={className}
        >
            {images.map((src, index) => (
                <SwiperSlide className='h-[100%]' key={index}>
                    <Image src={src} width={1800} height={1800} className="w-[100%] h-[100%] object-cover" alt={`Slide image ${index + 1}`} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default RegisterImagesSlider;
